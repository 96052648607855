import moment from "moment";
import Utils from "../../../utility";
import axios from "axios";

export const getCashoutPendingReport = (values, csvDownload) => {
  return (dispatch, getState) => {
    const { fromDate, toDate, storeId } = values;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.cashPendingReport,
      `?fromDate=${moment(fromDate).format("YYYY-MM-DD")}&toDate=${moment(
        toDate
      ).format("YYYY-MM-DD")}${storeId ? `&storeId=${storeId}` : ""}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        if (!respData.data?.length) {
          Utils.showAlert(2, "No data found");
          return;
        }
        dispatch({
          type: Utils.ActionName.STORE_AND_WAREHOUSE_REPORTS,
          payload: {
            cashPendingReport: respData.data,
            // count: respData.data[1],
          },
        });

        setTimeout(() => {
          csvDownload.current.link.click();
        }, 500);
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.cashPendingReport} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getCashoutReport = (values, csvDownload) => {
  return (dispatch, getState) => {
    const { fromDate, toDate, storeId } = values;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.cashout,
      `?fromDate=${moment(fromDate).format("YYYY-MM-DD")}&toDate=${moment(
        toDate
      ).format("YYYY-MM-DD")}${storeId ? `&storeId=${storeId}` : ""}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        if (!respData.data?.length) {
          Utils.showAlert(2, "No data found");
          return;
        }
        dispatch({
          type: Utils.ActionName.STORE_AND_WAREHOUSE_REPORTS,
          payload: {
            cashoutReport: respData.data,
            // count: respData.data[1],
          },
        });

        setTimeout(() => {
          csvDownload.current.link.click();
        }, 500);
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.cashout} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getStoreGrnReport = (values) => {
  return (dispatch, getState) => {
    const { fromDate, toDate, storeId } = values;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.GRNStoreReport,
      `?fromDate=${moment(fromDate).format("YYYY-MM-DD")}&toDate=${moment(
        toDate
      ).format("YYYY-MM-DD")}${storeId ? `&storeId=${storeId}` : ""}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        if (!respData.data?.length) {
          Utils.showAlert(2, "No data found");
          return;
        }
        const linkSource = `data:application/csv;base64,${respData.data}`;
        const downloadLink = document.createElement("a");
        const fileName = `storeGrnReport.csv`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        // dispatch({
        //   type: Utils.ActionName.STORE_AND_WAREHOUSE_REPORTS,
        //   payload: {
        //     cashoutReport: respData.data,
        //     // count: respData.data[1],
        //   },
        // });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.GRNStoreReport} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getWarehouseGrnReport = (values) => {
  return (dispatch, getState) => {
    const { fromDate, toDate, warehouseId } = values;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.GRNWarehouseReport,
      `?warehouseId=${warehouseId}&fromDate=${moment(fromDate).format(
        "YYYY-MM-DD"
      )}&toDate=${moment(toDate).format("YYYY-MM-DD")}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        const linkSource = `data:application/csv;base64,${respData.data}`;
        const downloadLink = document.createElement("a");
        const fileName = `warehouseGrnReport.csv`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        dispatch({
          type: Utils.ActionName.STORE_AND_WAREHOUSE_REPORTS,
          payload: {
            cashoutReport: respData.data,
            // count: respData.data[1],
          },
        });

        // setTimeout(() => {
        //   csvDownload.current.link.click();
        // }, 500);
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.GRNWarehouseReport} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getStoreRtvReport = (values) => {
  return (dispatch, getState) => {
    const { fromDate, toDate, storeId } = values;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.RTVStoreStockReport,
      `?fromDate=${moment(fromDate).format(
        "YYYY-MM-DD"
      )} 00:00:00&toDate=${moment(toDate).format("YYYY-MM-DD")} 23:59:59${
        storeId ? `&storeId=${storeId}` : ""
      }`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });

        if (!respData.data?.length) {
          Utils.showAlert(2, "No data found");
          return;
        }
        const linkSource = `data:application/csv;base64,${respData.data}`;
        const downloadLink = document.createElement("a");
        const fileName = `store_rtv_report.csv`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        // setTimeout(() => {
        //   csvDownload.current.link.click();
        // }, 500);
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.RTVStoreStockReport} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

/**
 * get Return Report
 */

export const getReturnReport = (values) => {
  return (dispatch, getState) => {
    const { fromDate, toDate, storeId } = values;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.returnReport,
      `?fromDate=${moment(fromDate).format(
        "YYYY-MM-DD"
      )} 00:00:00&toDate=${moment(toDate).format("YYYY-MM-DD")} 23:59:59${
        storeId ? `&storeId=${storeId}` : ""
      }`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });

        if (!respData.data?.length) {
          Utils.showAlert(2, "No data found");
          return;
        }
        const linkSource = `data:application/csv;base64,${respData.data}`;
        const downloadLink = document.createElement("a");
        const fileName = `Return-report.csv`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        // setTimeout(() => {
        //   csvDownload.current.link.click();
        // }, 500);
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.RTVStoreStockReport} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

/**
 * get GSTR
 */

export const getGSTR = (values) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const { fromDate, toDate, storeId } = values;
    axios(
      `${Utils.constants.baseUrl}${
        Utils.endPoints.GSTR
      }${storeId}?fromDate=${moment(fromDate).format(
        "YYYY-MM-DD"
      )}&toDate=${moment(toDate).format("YYYY-MM-DD")}`,
      {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        method: "GET",
        responseType: "blob", //Force to receive data in a Blob Format
      }
    )
      .then((response) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        const blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        const fileName = `gstr${storeId}.zip`;
        // saveAs(blob, filename);
        //Create a Blob from the PDF Stream
        // const file = new Blob([response.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(blob);
        //Open the URL on new Window

        // window.open(fileURL);
        // const linkSource = `data:application/pdf;base64,${base64data}`;
        const downloadLink = document.createElement("a");
        // const fileName = `tns${storeId}.pdf`;
        downloadLink.href = fileURL;
        downloadLink.download = fileName;
        downloadLink.click();
      })
      .catch((error) => {
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.GSTR} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
      });
  };
  // return (dispatch, getState) => {
  //   const { fromDate, toDate, storeId } = values;
  //   dispatch({
  //     type: Utils.ActionName.LOADING,
  //     payload: { loading: true },
  //   });
  //   Utils.api.getApiCall(
  //     Utils.endPoints.GSTR + storeId,
  //     `?fromDate=${moment(fromDate).format("YYYY-MM-DD")}&toDate=${moment(
  //       toDate
  //     ).format("YYYY-MM-DD")}`,
  //     (respData) => {
  //       dispatch({
  //         type: Utils.ActionName.LOADING,
  //         payload: { loading: false },
  //       });
  //       if (!respData.data?.length) {
  //         Utils.showAlert(2, "No data found");
  //         return;
  //       }
  //       // window.open(respData.data, '_blank');
  //       debugger;
  //       const linkSource = `data:application/csv;base64,${respData.data}`;
  //       const downloadLink = document.createElement("a");
  //       const fileName = `Gstr.zip`;
  //       downloadLink.href = linkSource;
  //       downloadLink.download = fileName;
  //       downloadLink.click();

  //       // dispatch({
  //       //   type: Utils.ActionName.STORE_AND_WAREHOUSE_REPORTS,
  //       //   payload: {
  //       //     cashPendingReport: respData.data,
  //       //     // count: respData.data[1],
  //       //   },
  //       // });

  //       // setTimeout(() => {
  //       //   csvDownload.current.link.click();
  //       // }, 500);
  //     },
  //     (error) => {
  //       dispatch({
  //         type: Utils.ActionName.LOADING,
  //         payload: { loading: false },
  //       });
  //       let { data } = error;
  //       Utils.showAlert(
  //         2,
  //         `${Utils.endPoints.cashPendingReport} GET Method error ${
  //           JSON.stringify(data?.message) || data?.error
  //         }`
  //       );
  //       // Utils.showAlert(2, data?.message?.join(",") || data?.error);
  //       // setSubmitting(true);
  //     }
  //   );
  // };
};
