import moment from "moment";
import Utils from "../../utility";

const initialState = {
  dashboard: {},
  dashboardChartData: [],
  dashboardDetailData: [],
  storeId: "",
  dashboardTrendData: "",
  chanelData: "",
  topPerformingProduct: "",
  inventoriesCount: 0,
  outOfStockCount: 0,
  deadInventoriesCount: 0,
  topPerformingCategory: "",
  discardValue: "",
  fromDate: moment().subtract(1, "days").format("YYYY-MM-DDT00:00"),
  toDate: moment().format("YYYY-MM-DDT23:59"),
  timeFrame: "daily",
  storeId: "",
  order: "DESC",
  // sortField: "createdAt",
  offset: 1,
  take: 10,
  // orderC: "",
  offsetP: 1,
  takeP: 10,
  orderP: "DESC",
  // sortFieldC: "createdAt",
  // offsetC: 1,
  // takeC: 10,
};
export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${Utils.ActionName.DASHBOARD}`:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};
