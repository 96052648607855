const endPoint = {
  login: "login",
  forgotPassword: "user/forget-password",
  resetPassword: "user/reset-password",
  user: "users",
  createUser: "users/create",
  addPropertyOwner: "user/property-owner/create",
  updatePropertyOwner: "user/property-owner/",
  brands: "brands",
  propertyOwner: "user/property-owner",
  store: "store/",
  createBrand: "brands/create",
  updateBrand: "/brands/",
  createStore: "store/create",
  warehouse: "warehouse/",
  warehouseCreate: "warehouse/create",
  products: "products/",
  approveProduct: "products/approve/",

  createProducts: "products/create",
  category: "category/",
  createCategory: "category/create",
  fileUpload: "files/upload",
  createBrandManager: "user/brand-manager/create",
  brandApprove: "brands/approve/",
  brandManagerApprove: "user/brand-manager/approve/",
  brandOwner: "user/brand-manager",
  oprationManager: "user/operations-manager",
  createOprationManager: "user/operations-manager/create",
  biller: "user/biller",

  createBiller: "user/biller/create",
  warehouseStock: "warehouse-stocks",
  createWarehouseStock: "warehouse-stocks/create",
  warehouseById: "warehouse-stocks/warehouse/",
  storeStock: "store-stocks",
  createStoreStock: "store-stocks/create",
  storeStockInvoice: "store-stocks/invoice/",
  w2s: "w2s-stocks",
  w2w: "w2w-stocks/",
  s2w: "s2w-stocks/",
  s2s: "s2s-stocks/",
  customers: "customers/",
  storeGRN: "GRN/store/",
  warehouseGRN: "GRN/warehouse/",
  GRN: "GRN/",
  createGRN: "GRN/create",
  SKUTimeline: "sku-timelines",
  sku: "sku",
  skuDetail: "sku/details",
  SKUTimelineSpecific: "sku-timelines/specific",
  createW2S: "w2s-stocks/create",
  createS2W: "s2w-stocks/create",
  createW2W: "w2w-stocks/create",
  createS2S: "s2s-stocks/create",
  deleteGrn: "GRN/delete/",
  rtvStoreStock: "rtvstore-stocks/store",
  rtvStoreWarehouse: "rtvstore-stocks/warehouse",
  createRtv: "rtvstore-stocks/create",
  orderCreate: "orders/create",
  orders: "orders",
  runnerAsign: "/runner/",
  orderReturn: "orderReturn/",
  createPo: "po/create",
  PO: "po/store/",
  POSingle: "po/singleStoreOrder/",
  appVersion: "appVersion",
  appVersionForDashboard: "appVersionForDashboard",
  banners: "banners",
  dashboard: "users/dashboard",
  orderInvoice: "orders/invoice/",
  updateStatusW2S: "w2s-stocks/updateStatus/",
  updateStatusW2W: "w2w-stocks/updateStatus/",
  updateStatusS2S: "s2s-stocks/updateStatus/",
  updateStatusS2W: "s2w-stocks/updateStatus/",
  w2sPdf: "w2s-stocks/pdf/",
  w2wPdf: "w2w-stocks/pdf/",
  s2sPdf: "s2s-stocks/pdf/",
  s2wPdf: "s2w-stocks/pdf/",
  updateQuantityS2w: "s2w-stocks/updateProductQuantity/",
  updateQuantityW2w: "w2w-stocks/updateProductQuantity/",
  updateQuantityW2s: "w2s-stocks/updateProductQuantity/",
  updateQuantityS2s: "s2s-stocks/updateProductQuantity/",
  allReturns: "orderReturn/allReturns",
  poWarehouse: "po/warehouse",
  sigleWarehousePo: "po/singleWarehouseOrder/",
  verifyRtv: "rtvstore-stocks/verify/",
  returnPdf: "/orderReturn/pdf/",
  updateStoreStock: "/store-stocks/updateStock",
  updateWarehouseStock: "/warehouse-stocks/updateStock",
  cashPendingReport: "/store/cashPending-report",
  cashout: "/cashOut",
  getOrderById: "orders/list/",
  orderStatusUpdate: "orders/status/",
  rtvStoreStockPdf: "/rtvstore-stocks/pdf/",
  updateRazorToken: "/orders/savePaymentToken",
  cancelOrder: "/orderReturn/cancel-order/",
  orderCSVReport: "/orders/orderReportCSV",
  updateSellingAndCostPrice: "/store-stocks/updateStockPrice",
  RTVStoreStockReport: "rtvstore-stocks/RTVreport",
  GRNStoreReport: "GRN/GRNreportStore",
  GRNWarehouseReport: "GRN/GRNreportWarehouse",
  // dahboard: "/users/dashboard",
  dailyStockReport: "store-stocks/dailyReport",
  grnBulkUpload: "GRN/upload/",
  changeUserPassword: "users/password/",
  discardReports: "/rtvstore-stocks/discardReport",
  currentStock: "/store-stocks/report/storeStocksCSV",
  returnReport: "/orderReturn/report/returncsv",
  productsForDropdown: "/products/store/stocks",
  recipes: "/recipes",
  createRecipes: "/recipes/create",
  // addIngredient: "/recipes/createRecipeIngredient",
  razorPayOrder: "/store/razorPay",
  storeRoyalty: "store/royalty",
  royaltyDetail: "store/royalty/detailed/",
  addIngredient: "/recipes/createRecipeIngredient",
  refreshToken: "refresh-token",
  logout: "logout",
  // addIngredient: "/recipes/createRecipeIngredient",
  credit: "credit",
  cashPendingEdit: "store/cashPendingEdit/",
  trends: "store/trends/",
  chanelDashboard: "store/channelDataDashboard/",
  topPerformingProduct: "store/topPerformingProducts/",
  topPerformingCategory: "store/topPerformingCategories/",
  inventories: "store/inventories/",
  deadInventories: "store/deadinventories/",
  deadInventoryReports: "store/deadInventoryReportStore/",
  outOfStock: "store/outofstock/",
  discadValue: "store/discardValues/",
  outOfStockReport: "store/OutOfStockReportCSV/",
  GSTR: "store/combined/",
  deadInventoriesCSV: "store/deadInventoryReportStore/",
  outOfStockCSV: "store/OutOfStockReportCSV/",
};
export default endPoint;
