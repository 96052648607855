import Utils from "../../../utility";

const initialState = {
  storeStockList: [],
  order: "DESC",
  sortField: "createdAt",
  offset: 1,
  take: 10,
  search: "",
  exportData: [],
  count: 0,
  printGrnData: {},
  grandTotalAddedGRN: 0,
  categoryId: "",
  brandId: "",
};
export const storeStockReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${Utils.ActionName.STORE_STOCK}`:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};
