import * as React from "react";
import { useState } from "react";
import Utils from "../../../utility";
import DeleteConfirmationPopup from "../../../component/modal/deleteModel";
import Select from "../../../component/select";
import MenuItem from "@mui/material/MenuItem";
import { getStoreList } from "../../storeRelated/store/action";
import useDebounce from "../../../hooks/debounce";

import { getReturnList, returnInvoice } from "./action";
// import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Visibility";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
// import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
// import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import AddButton from "../../../component/button/buttonWithIcon";
import Breadcrumbs from "../../../component/breadcrumb";
import SearchInput from "../../../component/searchInput";

// import Button from "@mui/material/Button";
// import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
// import DeleteIcon from '@mui/icons-material/Delete';
// import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Modal from "../../../component/modal";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
// import Modal from "../../component/modal"
import AddUser from "./generateReport";
import moment from "moment";
import { Button } from "@mui/material";
// import { getCategoryList } from "../productCategory/action";
// import { getBrandList } from "../brands/action";
// import { getBrandOwnerList } from "../../userAccount/brandOwner.js/action";

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "Invoice",
    sorting: false,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Date",
    sorting: true,
  },
  {
    id: "Barcode",
    numeric: true,
    disablePadding: false,
    label: "Barcode",
    sorting: false,
  },
  // {
  //   id: "Subcatergory",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Subcatergory",
  //   sorting: false,
  // },
  {
    id: "Quantity",
    numeric: true,
    disablePadding: false,
    label: "Quantity",
    sorting: false,
  },
  {
    id: "description",
    numeric: true,
    disablePadding: false,
    label: "Product MRP	",
    sorting: false,
  },
  {
    id: "categoryName",
    numeric: true,
    disablePadding: false,
    label: "Return Amount	",
    sorting: false,
  },
  // {
  //   id: "action",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Store Name",
  //   sorting: false,
  // },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
    sorting: false,
  },
];

function EnhancedTableHead(props) {
  const {
    // onSelectAllClick,

    // orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
    // dispatch({type:Utils.ActionName.USER_LIST,payload:{sortField:}})
  };
  const { order, sortField } = useSelector((state) => state.productsReducer);
  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? "right" : "left"}
            // padding={headCell.disablePadding ? "none" : "normal"}

            sortDirection={
              sortField === headCell.id ? order.toLowerCase() : false
            }
          >
            {headCell?.sorting ? (
              <TableSortLabel
                // disableSortBy = {false}
                // hideSortIcon = {true}
                active={sortField === headCell.id}
                direction={
                  sortField === headCell.id ? order.toLowerCase() : "asc"
                }
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {sortField === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "DESC"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, handleStoreSelect, storeId } = props;
  const { storeListForDropdown } = useSelector((state) => state.commonReducer);

  // const { storeList } = useSelector((state) => state.storeReducer);
  const dispatch = useDispatch();
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
      className="m-t-10"
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Returns
      </Typography>
      <Stack sx={{ width: 300 }}>
        <Select
          handleChange={(e) => {
            dispatch({
              type: Utils.ActionName.RETURNS,
              payload: { storeId: e.target.value },
            });
            dispatch(getReturnList());
            // dispatch(getOrderExportList());
          }}
          value={storeId}
          label={"Select Stores"}
          option={storeListForDropdown?.map((item) => (
            <MenuItem value={item?.id} key={item.id}>
              {item?.storename}
            </MenuItem>
          ))}
          name="Stores"
        />
      </Stack>
    </Toolbar>
  );
};

export default function EnhancedTable() {
  const dispatch = useDispatch();
  const { returnList, order, storeId, sortField, offset, take, search, count } =
    useSelector((state) => state.returnReducer);
  const debouncedSearch = useDebounce(search, 500);
  React.useEffect(() => {
    if (storeId) dispatch(getReturnList());
    // dispatch(getRtvStoreStockList("", true));
  }, [debouncedSearch]);

  const [open, setOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  const [isEdit, setIsEdit] = useState(null);
  React.useEffect(() => {
    // dispatch(getStoreList());
    // dispatch(getReturnList());
    // dispatch(getBrandList());
    // dispatch(getBrandOwnerList());
  }, [dispatch]);

  const handleRequestSort = (event, property) => {
    const isAsc = sortField === property && order === "ASC";
    // setOrder(isAsc ? "desc" : "asc");
    dispatch({
      type: Utils.ActionName.RETURNS,
      payload: { sortField: property, order: isAsc ? "DESC" : "ASC" },
    });
    dispatch(getReturnList());
    // setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    dispatch({
      type: Utils.ActionName.RETURNS,
      payload: { offset: newPage + 1 },
    });
    dispatch(getReturnList());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: Utils.ActionName.RETURNS,
      payload: { take: event.target.value, offset: 1 },
    });
    dispatch(getReturnList());
  };

  // Avoid a layout jump when reaching the last page with empty rows.

  const handleSearch = (e) => {
    dispatch({
      type: Utils.ActionName.RETURNS,
      payload: { search: e.target.value, take: take, offset: 1 },
    });
    // dispatch(getReturnList());
  };
  const handleSearchClear = (e) => {
    dispatch({
      type: Utils.ActionName.RETURNS,
      payload: { search: "", take: take, offset: 1 },
    });
  };

  return (
    <>
      {" "}
      <Breadcrumbs />
      <Box className="tableContainer">
        <div className="Dflex table-filter-head ">
          <AddButton
            text="Generate a report"
            className="btn-purple m-l-10"
            onPress={() => setOpen(true)}
          />
          <Box className="search_bar ">
            <SearchInput
              value={search}
              placeholder="Search here..."
              handleSearch={handleSearch}
              clearSearch={handleSearchClear}
            />
          </Box>
        </div>

        <TableContainer>
          <EnhancedTableToolbar />
          <Table
            // sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            // size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              // numSelected={pro.length}
              order={order}
              orderBy={sortField}
              onRequestSort={handleRequestSort}
              // rowCount={products.length}
            />

            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {/* {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
              {returnList?.length &&
                returnList.map((row, index) => {
                  // const isItemSelected = isSelected(row.name);
                  // const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.name)}
                      // role="checkbox"
                      // aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      // selected={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell> */}
                      <TableCell
                        component="th"
                        id={index}
                        scope="row"
                        // padding="none"
                      >
                        {row.id}
                      </TableCell>
                      <TableCell align="left">
                        {moment(row?.createdAt).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="left">
                        {row?.product?.barcode || "N/A"}
                      </TableCell>
                      {/* <TableCell align="left">
                        {row?.product?.category?.subCategoryOf || "N/A"}
                      </TableCell> */}
                      <TableCell align="left">
                        {row?.quantity || "N/A"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.product?.productMrp
                          ? row?.product?.productMrp
                          : "N/A"}
                      </TableCell>
                      <TableCell align="left">
                        {row.totalAmount ? row?.totalAmount : "-"}
                      </TableCell>
                      {/* <TableCell align="left">
                        {row?.store?.storename}
                      </TableCell> */}
                      <TableCell align="left">
                        <>
                          <DeleteIcon
                            onClick={() => {
                              dispatch(returnInvoice(row.return?.id));
                            }}
                          />
                        </>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {/* {[].length > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * 0,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={count}
          rowsPerPage={take}
          page={offset - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      {/* <Modal
        header={`Generate Billing Report`}
        children={
          <AddUser setOpen={setOpen} isEdit={isEdit} setIsEdit={setIsEdit} />
        }
        handleClose={() => {
          setOpen(false);
          setIsEdit(null);
        }}
        open={open}
      /> */}
      <DeleteConfirmationPopup
        title="Product"
        handleClose={() => setDeleteConfirmation(null)}
        okButtonClick={() => {
          // dispatch(deleteProduct(deleteConfirmation));
          setDeleteConfirmation(null);
        }}
        open={deleteConfirmation}
        minWidth={500}
      />
      <Modal
        header={`Generate Return Report`}
        children={
          <AddUser setOpen={setOpen} isEdit={isEdit} setIsEdit={setIsEdit} />
        }
        handleClose={() => {
          setOpen(false);
          setIsEdit(null);
        }}
        open={open}
      />
    </>
  );
}
