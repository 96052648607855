import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import DatePicker from "../../../component/date";
// import StoreTable from "./tables/storeTable";
import Chip from "@mui/material/Chip";
import TopCategory from "../tables/category";
import Stack from "@mui/material/Stack";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import TopProduct from "../tables/topProducts";
// import { useNavigate, useHistory } from "react-router-dom";
// import Uti from "../../utility/utils";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
// import Authlayout from "../../component/layout/authLayout";
// import Input from "../../component/input";
import { Box, MenuItem, Paper } from "@mui/material";
import Select from "../../../component/select";
// import { getProducts } from "../../productRelated/products";
// import DetailGRN from "./detailGRN";
import TopBrands from "../tables/topBrand";
import {
  getChanelData,
  getDeadInventory,
  getDeadInvReport,
  getDiscardValue,
  getInventory,
  getOutOfStock,
  getoutOfStockReport,
  getTopPerformingCategory,
  getTopPerformingProduct,
  getTrendData,
} from "../action";
import Utils from "../../../utility";
import NormalButton from "../../../component/button";
import AutoComplete from "../../../component/input/autocomplete";
// import TopProducts from "./tables/topProducts";
// import BottomProducts from "./tables/bottomProducts";
// import NormalButton from "../../component/button";
const Index = () => {
  const [chartType, setChartType] = useState("orders");

  //   const [storeId, setStoreId] = useState("*");
  const [storeId, setStoreId] = useState(null);

  const [monthlyData, setMothlyData] = useState([]);
  const {
    dashboard,
    dashboardTrendData,
    dashboardChartData,
    dashboardDetailData,
    chanelData,
    topPerformingProduct,
    inventoriesCount,
    discardValue,
    outOfStockCount,
    deadInventoriesCount,
    topPerformingCategory,
    // discadValue,
    fromDate,
    toDate,
    timeFrame,
  } = useSelector((state) => state.dashboardReducer);

  // console.log(
  //   dashboardTrendData , dashboardTrendData[chartType],
  //   dashboardTrendData[chartType]?.count,
  //   dashboardTrendData[chartType]?.result,
  //   dashboardTrendData[chartType]?.result.length,
  //   "shashank"
  // );

  const options = {
    chart: {
      type: "column",
    },
    title: {
      align: "center",
      text: chartType,
    },
    // subtitle: {
    //   align: "center",
    //   text: "Orders",
    // },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
    },
    xAxis: {
      type: "category",
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    legend: {
      enabled: false,
    },
    // plotOptions: {
    //   series: {
    //     borderWidth: 0,
    //     dataLabels: {
    //       enabled: true,
    //       format: "{point.y:.1f}%",
    //     },
    //   },
    // },

    // tooltip: {
    //   headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
    //   pointFormat:
    //     '<span style="color:{point.color}">{point.name}</span>: ' +
    //     "<b>{point.y:.2f}%</b> of total<br/>",
    // },

    series: [
      {
        name: chartType,
        color: "#ffde59",
        // colorByPoint: true,
        data:
          dashboardTrendData &&
          dashboardTrendData[chartType] &&
          dashboardTrendData[chartType]?.count &&
          dashboardTrendData[chartType]?.result &&
          dashboardTrendData[chartType]?.result?.length &&
          dashboardTrendData[chartType]?.result?.map((item) => {
            return { name: `${item?.date}`, y: item?.count };
          }),
      },
    ],
  };
  const { storeListForDropdown } = useSelector((state) => state.commonReducer);

  const dispatch = useDispatch();
  const [trendTime, setTrendTime] = useState("daily");
  const [chanelTime, setChanelTime] = useState("daily");
  // const [productTime, setProductTime] = useState("daily");
  // const [categoryTime, setCategoryTime] = useState("daily");
  const chanelOptions = {
    chart: {
      type: "pie",
      custom: {},
      //   events: {
      //     render() {
      //       const chart = this,
      //         series = chart.series[0];
      //       let customLabel = chart.options.chart.custom.label;

      //       if (!customLabel) {
      //         customLabel = chart.options.chart.custom.label = chart.renderer
      //           .label("Total<br/>" + "<strong>2 877 820</strong>")
      //           .css({
      //             color: "#000",
      //             textAnchor: "middle",
      //           })
      //           .add();
      //       }

      //       const x = series.center[0] + chart.plotLeft,
      //         y =
      //           series.center[1] + chart.plotTop - customLabel.attr("height") / 2;

      //       customLabel.attr({
      //         x,
      //         y,
      //       });
      //       // Set font size based on chart diameter
      //       customLabel.css({
      //         fontSize: `${series.center[2] / 12}px`,
      //       });
      //     },
      //   },
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    title: {
      text: "",
    },
    // subtitle: {
    //   text: 'Source: <a href="https://www.ssb.no/transport-og-reiseliv/faktaside/bil-og-transport">SSB</a>',
    // },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.0f}%</b>",
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        cursor: "pointer",
        borderRadius: 8,
        dataLabels: [
          {
            enabled: true,
            distance: 20,
            format: "{point.name}",
          },
          {
            enabled: true,
            distance: -15,
            format: "{point.percentage:.0f}%",
            style: {
              fontSize: "0.9em",
            },
          },
        ],
        showInLegend: true,
      },
    },
    series: [
      {
        name: "Channels",
        colorByPoint: true,
        innerSize: "75%",
        data:
          chanelData?.length &&
          chanelData?.map((item) => {
            return { name: `${item?.channel}`, y: item?.sales };
          }),
      },
    ],
  };
  // chanelData?.length &&
  // chanelData?.map((item) => {
  //   return { name: `${item?.channel}`, y: item?.sales };
  // }),
  // useEffect(() => {
  //   if (dashboardChartData?.length) {
  //     let MonthlyData = dashboardChartData.filter(
  //       (item) => item && item["monthly"]
  //     )[0]?.monthly;
  //     // Get the current month
  //     const currentMonth = new Date().toLocaleString("default", {
  //       month: "long",
  //     });

  //     // Sort the array in ascending order based on the current month
  //     MonthlyData.sort((a, b) => {
  //       const monthOrder = [
  //         "January",
  //         "February",
  //         "March",
  //         "April",
  //         "May",
  //         "June",
  //         "July",
  //         "August",
  //         "September",
  //         "October",
  //         "November",
  //         "December",
  //       ];
  //       return (
  //         monthOrder.indexOf(a.month?.trim()) -
  //         monthOrder.indexOf(b.month?.trim())
  //       );
  //     });

  //     // Find the index of the current month in the sorted array
  //     const currentIndex = MonthlyData.findIndex(
  //       (item) => item.month.trim() === currentMonth
  //     );

  //     // Rotate the array to start from the current month
  //     const rotatedArray = MonthlyData.slice(currentIndex + 1).concat(
  //       MonthlyData.slice(0, currentIndex + 1)
  //     );
  //     setMothlyData(rotatedArray);
  //   }
  // }, [dashboardChartData]);

  useEffect(() => {
    // dispatch(getTrendData());
    // dispatch(getTrendData());
    // dispatch(getInventory());
    // dispatch(getDeadInventory());
    // dispatch(getDiscardValue());
    // dispatch(getOutOfStock());
    // dispatch(getTopPerformingCategory());
    // dispatch(getTopPerformingProduct());
  }, []);

  return (
    <React.Fragment>
      <Grid container rowSpacing={3} columnSpacing={2} className="m-t-5 m-b-20">
        <Grid item xs={12} sm={6} lg={4}>
          <AutoComplete
            freeSolo={false}
            // onBlur={handleBlur}
            values={storeId}
            onChange={(event, value) => {
              if (value !== null) {
                setStoreId(value?.id);
                dispatch({
                  type: Utils.ActionName.DASHBOARD,
                  payload: {
                    storeId: value?.id,
                  },
                });
                // dispatch(getDashboardTrend(value?.id));
                // setFieldValue("storeName", value?.storename);
              }
            }}
            name={"storeId"}
            label={"Store"}
            options={storeListForDropdown}
            getOptionLabel={(option) => option?.storename}
            // isRequired={true}
            getOptionSelected={(option, value) => {
              return `${option?.id}` === `${value?.id}`;
            }}
          />
          {/* </Grid> */}
        </Grid>
        <Grid item xs={6} sm={3} lg={2}>
          <DatePicker
            value={fromDate}
            name="fromDate"
            handleChange={(newVal) => {
              dispatch({
                type: Utils.ActionName.DASHBOARD,
                payload: {
                  fromDate: `${moment(newVal).format("YYYY-MM-DD")}T00:00`,
                },
              });
            }}
            label="From Date"
            // error={errors.fromDate}
            // maxDate={new Date(moment(new Date()).subtract(1, "days"))}
          />
        </Grid>
        <Grid item xs={6} sm={3} lg={2}>
          <DatePicker
            value={toDate}
            name="toDate"
            handleChange={(newVal) => {
              dispatch({
                type: Utils.ActionName.DASHBOARD,
                payload: {
                  toDate: `${moment(newVal).format("YYYY-MM-DD")}T23:59`,
                },
              });
            }}
            label="To Date"
            // error={errors.toDate}
            // maxDate={new Date(moment(new Date()).subtract(1, "days"))}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} lg={4}> */}
        <div className="Dflex al-cnt fl-end m-t-25">
          <NormalButton
            className="btn-purple m-l-30"
            buttonText={"Submit"}
            onPress={() => {
              setTrendTime("daily");
              setChanelTime("daily");
              dispatch(getTrendData());
              dispatch(getChanelData());
              dispatch(getInventory());
              dispatch(getDeadInventory());
              dispatch(getDiscardValue());
              dispatch(getOutOfStock());
              dispatch(getTopPerformingCategory());
              dispatch(getTopPerformingProduct());
            }}
            // type="submit"
          />
        </div>
        {/* </Grid> */}
      </Grid>
      {storeId ? (
        <React.Fragment>
          <div className="Dflex">
            <div className="bg-white w-50 m-r-10">
              <div className="Dflex sp-bt al-cnt">
                <Typography>Trends</Typography>
                <Box className="w-110">
                  <Select
                    handleChange={(e) => {
                      dispatch({
                        type: Utils.ActionName.DASHBOARD,
                        payload: {
                          timeFrame: e.target.value,
                        },
                      });
                      setTrendTime(e.target.value);
                      if (storeId) dispatch(getTrendData());
                    }}
                    value={trendTime}
                    name={`trend`}
                    //   label={""}
                    option={["daily", "weekly", "monthly"]?.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                    //   error={errors.product && errors.product[index]?.unit}
                  />
                </Box>
              </div>
              <div className="Dflex wrap m-t-10 m-b-10">
                <Paper
                  elevation={2}
                  sx={{
                    m: 1,
                    p: 2.5,
                    background: chartType == "orders" ? "#ffde59" : "",
                    cursor: "pointer",
                  }}
                  onClick={() => setChartType("orders")}
                >
                  <Typography
                    className="boldText center"
                    onClick={() => setChartType("orders")}
                  >
                    {dashboardTrendData?.orders?.count || 0}
                  </Typography>
                  <Typography className="boldText">Orders</Typography>
                </Paper>
                <Paper
                  elevation={2}
                  sx={{
                    m: 1,
                    p: 2.5,
                    background: chartType == "netSales" ? "#ffde59" : "",
                    cursor: "pointer",
                  }}
                  onClick={() => setChartType("netSales")}
                >
                  <Typography className="boldText center">
                    {" "}
                    ₹ {dashboardTrendData?.netSales?.count || 0}
                  </Typography>
                  <Typography className="boldText"> Net Sales</Typography>
                </Paper>
                <Paper
                  elevation={2}
                  sx={{
                    m: 1,
                    p: 2.5,
                    background: chartType == "aov" ? "#ffde59" : "",
                    cursor: "pointer",
                  }}
                  onClick={() => setChartType("aov")}
                >
                  <Typography className="boldText center">
                    {dashboardTrendData?.aov?.count || 0}
                  </Typography>
                  <Typography className="boldText">AOV</Typography>
                </Paper>
                <Paper
                  elevation={2}
                  sx={{
                    m: 1,
                    p: 2.5,
                    background: chartType == "returns" ? "#ffde59" : "",
                    cursor: "pointer",
                  }}
                  onClick={() => setChartType("returns")}
                >
                  <Typography className="boldText center">
                    {dashboardTrendData?.returns?.count || 0}
                  </Typography>
                  <Typography className="boldText">Returns</Typography>
                </Paper>
                <Paper
                  elevation={2}
                  sx={{
                    m: 1,
                    p: 2.5,
                    background: chartType == "discount" ? "yellow" : "",
                    cursor: "pointer",
                  }}
                  onClick={() => setChartType("discount")}
                >
                  <Typography className="boldText center">
                    ₹ {dashboardTrendData?.discount?.count || 0}
                  </Typography>
                  <Typography className="boldText">Discount</Typography>
                </Paper>
                <Paper
                  elevation={2}
                  sx={{
                    m: 1,
                    p: 2.5,
                    background: chartType == "grossMargin" ? "#ffde59" : "",
                    cursor: "pointer",
                  }}
                  onClick={() => setChartType("grossMargin")}
                >
                  <Typography className="boldText center">
                    {dashboardTrendData?.grossMargin?.count || 0}%
                  </Typography>
                  <Typography className="boldText">Gross Margin</Typography>
                </Paper>

                {/* </Paper> */}
              </div>
              <React.Fragment key={chartType}>
                <HighchartsReact
                  highcharts={Highcharts}
                  ctorType={"stockChart"}
                  options={options}
                />
              </React.Fragment>
            </div>
            <div className="bg-white w-50">
              <div className="Dflex sp-bt al-cnt">
                <Typography>Channels </Typography>
                <Box className="w-110">
                  <Select
                    handleChange={(e) => {
                      dispatch({
                        type: Utils.ActionName.DASHBOARD,
                        payload: {
                          timeFrame: e.target.value,
                        },
                      });
                      setChanelTime(e.target.value);
                      if (storeId) dispatch(getChanelData());
                    }}
                    value={chanelTime}
                    name={`storeId`}
                    //   label={""}
                    option={["daily", "weekly", "monthly"]?.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                    //   error={errors.product && errors.product[index]?.unit}
                  />
                </Box>
              </div>

              <React.Fragment>
                <HighchartsReact
                  highcharts={Highcharts}
                  ctorType={"stockChart"}
                  options={chanelOptions}
                />
              </React.Fragment>
            </div>
          </div>
          <div>
            <div className="Dflex wrap m-t-10 m-b-10">
              <Paper
                elevation={2}
                sx={{ width: "23.5%", m: 0.5, p: 2.5 }}
                className="bg-primary"
              >
                <Typography className="boldText center">
                  Rs. {inventoriesCount || 0}
                </Typography>
                <Typography className="boldText center">
                  Inventory In Hand
                </Typography>
              </Paper>
              <Paper
                elevation={2}
                sx={{ width: "23.5%", m: 0.5, p: 2.5, cursor: "pointer" }}
                className="bg-primary"
                onClick={() => dispatch(getDeadInvReport())}
              >
                <Typography className="boldText center">
                  Rs. {deadInventoriesCount || 0}
                </Typography>
                <Typography className="boldText center">
                  Dead Inventory
                </Typography>
              </Paper>
              <Paper
                elevation={2}
                sx={{ width: "23.5%", m: 0.5, p: 2.5 }}
                className="bg-primary"
              >
                <Typography className="boldText center">
                  Rs. {discardValue || 0}
                </Typography>
                <Typography className="boldText center">
                  Discard Last Month
                </Typography>
              </Paper>
              <Paper
                elevation={2}
                sx={{ width: "23.5%", m: 0.5, p: 2.5, cursor: "pointer" }}
                className="bg-primary"
                onClick={() => dispatch(getoutOfStockReport())}
              >
                <Typography className="boldText center">
                  {outOfStockCount || 0} SKUs
                </Typography>
                <Typography className="boldText center">
                  Out Of Stock
                </Typography>
              </Paper>

              {/* </Paper> */}
            </div>
          </div>
          {/* <Paper elevation={2}> */}
          {/* <h3 style={{ textAlign: "center" }}>Top Brands</h3> */}
          <div className="Dflex">
            <div className="bg-white w-50 m-r-10">
              <div className="Dflex sp-bt al-cnt">
                <Typography>Top Product</Typography>
                {/* <Box className="w-110">
              <Select
                handleChange={(e) => {
                  dispatch({
                    type: Utils.ActionName.DASHBOARD,
                    payload: {
                      timeFrame: e.target.value,
                    },
                  });
                  setProductTime(e.target.value);
                  dispatch(getTopPerformingProduct());
                }}
                value={productTime}
                name={`Product`}
                //   label={""}
                option={["daily", "Weekly", "monthly"]?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
                //   error={errors.product && errors.product[index]?.unit}
              />
            </Box> */}
              </div>
              <TopProduct />
              {/* <TopBrands
                rows={
                  dashboardDetailData?.length > 2 &&
                  dashboardDetailData[2]?.Brands
                }
              /> */}
            </div>
            <div className="bg-white w-50">
              <div className="Dflex sp-bt al-cnt">
                <Typography>Top Category</Typography>
                {/* <Box className="w-110">
              <Select
                handleChange={(e) => {
                  dispatch({
                    type: Utils.ActionName.DASHBOARD,
                    payload: {
                      timeFrame: e.target.value,
                    },
                  });
                  setCategoryTime(e.target.value);
                  dispatch(getTopPerformingCategory());
                }}
                value={categoryTime}
                name={`category`}
                //   label={""}
                option={["daily", "weekly", "monthly"]?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
                //   error={errors.product && errors.product[index]?.unit}
              />
            </Box> */}
              </div>

              <TopCategory
              // rows={
              //   dashboardDetailData?.length > 2 &&
              //   dashboardDetailData[2]?.Brands
              // }
              />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {/* :<React.Fragment></React.Fragment>} */}

      {/* </Paper> */}
    </React.Fragment>
  );
};
export default Index;
