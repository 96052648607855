const B_Permission = {
  usersRelated: {
    view: true,
    add: true,
    edit: true,
    delete: false,
  },
  user: {
    view: false,
    add: false,
    edit: true,
    delete: false,
  },
  customer: {
    view: false,
    add: false,
    edit: true,
    delete: false,
  },
  brandOwner: {
    view: true,
    add: true,
    edit: false,
    delete: false,
  },
  propertyOwner: {
    view: false,
    add: true,
    edit: true,
    delete: false,
  },
  // storesRelated: {
  //   view: true,
  //   add: false,
  //   edit: false,
  //   delete: false,
  // },
  grnPo: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  grn: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  store: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  royalty: { view: true },
  royaltyHistory: { view: true },
  reportsRelated: {
    view: true,
  },
  storeStock: {
    view: true,
    add: true,
    edit: false,
    delete: false,
    bulkUpload: true,
    currentStockReport: true,
  },

  // stockTransfer: {
  //   view: true,
  //   add: true,
  //   edit: false,
  //   delete: false,
  //   approve: true,
  //   reject: false,
  // },
  stockTransferS2S: {
    view: true,
    add: false,
    edit: false,
    delete: false,
    approve: true,
    reject: false,
  },
  stockTransferS2W: {
    view: true,
    add: false,
    edit: false,
    delete: false,
    approve: true,
    reject: false,
  },
  stockTransferW2S: {
    view: true,
    add: false,
    edit: false,
    delete: false,
    approve: true,
    reject: false,
  },

  order: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  // return: {
  //   view: true,
  //   add: false,
  //   edit: false,
  //   delete: false,
  // },
  productRelated: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  product: {
    view: true,
    add: true,
    edit: false,
    delete: false,
  },
  brand: {
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  productCategory: {
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
};

const BO_Permission = {
  dashboard: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  usersRelated: {
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  storesRelated: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  store: {
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  storeStock: {
    view: true,
    add: true,
    edit: false,
    delete: false,
  },
  // stockTransfer: {
  //   view: true,
  //   add: true,
  //   edit: false,
  //   delete: false,
  //   approveReject: true,
  // },
  // warehouseRelated: {
  //   view: true,
  //   add: false,
  //   edit: false,
  //   delete: false,
  // },
  warehouseStock: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  warehous: {
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  productRelated: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  product: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  brand: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  productCategory: {
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
  invoiceBilling: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },

  order: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  return: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
};

const PO_Permission = {
  dashboard: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  usersRelated: {
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  storesRelated: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  skutimeline: {
    view: true,
  },
  reportsRelated: {
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
  storeRtvReport: {
    view: true,
  },
  discardRtvReport: {
    view: true,
  },
  cashoutReport: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  grnPo: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  grn: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  storePo: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  store: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  royalty: { view: true },
  royaltyHistory: { view: true },
  storeStock: {
    view: true,
    add: true,
    edit: true,
    delete: false,
    bulkUpload: true,
    currentStockReport: true,
  },
  storeGrnReport: {
    view: true,
  },

  stockTransfer: {
    view: true,
    add: false,
    edit: false,
    delete: false,
    approveReject: false,
  },
  stockTransferS2S: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    report: true,
    approveReject: true,
  },

  // stockTransferS2W: {
  //   view: true,
  //   add: false,
  //   edit: false,
  //   delete: false,
  //   report: true,
  //   approveReject: false,
  // },
  // stockTransferW2S: {
  //   view: true,
  //   add: false,
  //   edit: false,
  //   delete: false,
  //   report: true,
  //   approveReject: true,
  // },
  order: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  invoiceBilling: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  billingReport: {
    view: true,
  },
  return: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  productRelated: {
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
  product: {
    view: true,
    add: true,
    edit: false,
    delete: true,
  },
  // brand: {
  //   view: false,
  //   add: false,
  //   edit: false,
  //   delete: false,
  // },
  brand: {
    view: false,
    add: true,
    edit: true,
    delete: true,
  },
  // productCategory: {
  //   view: true,
  //   add: false,
  //   edit: false,
  //   delete: false,
  // },
  stockLedger: {
    view: true,
  },
  brandOwner: {
    view: true,
    add: true,
    edit: true,
    delete: false,
  },
  usersRelated: {
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
  gstr: {
    view: true,
  },
  // customer: {
  //   view: true,
  //   add: true,
  //   edit: true,
  //   delete: true,
  // },
};

const AM_Permission = {
  usersRelated: {
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
  user: {
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
  customer: {
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
  brandOwner: {
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
  propertyOwner: {
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
};

const CAM_Permission = {
  productRelated: {
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
  product: {
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
  brand: {
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
  productCategory: {
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
};

const SM_Permission = {
  usersRelated: {
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
  storesRelated: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  store: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  storeStock: {
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
  warehouseRelated: {
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
  warehouseRTV: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  warehouseDiscardRTV: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  stockTransfer: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    approveReject: true,
  },
  stockTransferS2S: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    approveReject: true,
  },
  stockTransferS2W: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    approveReject: true,
  },
  invoiceBilling: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  order: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
  return: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
};

const WM_Permission = {
  usersRelated: {
    view: false,
    add: false,
    edit: false,
    delete: false,
  },

  stockTransfer: {
    view: true,
    add: false,
    edit: true,
    delete: true,
    approveReject: true,
  },
  stockTransferW2S: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    approveReject: true,
  },
  stockTransferW2W: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    approveReject: true,
  },
  warehouseRelated: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },

  warehouseStock: {
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
  warehous: {
    view: true,
    add: false,
    edit: false,
    delete: false,
  },
};

const OM_Permission = {
  superAdmin: { view: true },
  banner: { view: true },
  onlineOrder: { view: true },
  appVersion: { view: true },
  dashboard: { view: true },
  storesRelated: { view: true },
  store: { view: true },
  royalty: { view: true },
  royaltyHistory: { view: true },
  skutimeline: { view: true },
  reportsRelated: { view: true },
  billingReport: { view: true },
  return: { view: true },
  discardRtvReport: { view: true },
  storeStock: { view: true },
  cashoutReport: { view: true },
  pendingCashReport: { view: true },
  storeRtvReport: { view: true },
  credit: { view: true },
  grnPo: { view: true },
  GRN: { view: true },
  storePo: { view: true },
  stockTransfer: { view: true },
  stockTransferS2S: { view: true },
  usersRelated: { view: true },
  user: { view: true },
  customer: { view: true },
  brandOwner: { view: true },
  propertyOwner: { view: true },
  productRelated: { view: true },
  product: { view: true },
  productCategory: { view: true },
  brand: { view: true },
  recipes: { view: true },
  // productRelated: { view: true },
  // productRelated: { view: true },
  // productRelated: { view: true },
};
const Nine_Five_Three_Permission = {
  warehouseRelated: {
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
  warehouseStock: {
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
  warehouseRTV: {
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
  warehouseDiscardRTV: {
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
  warehous: {
    view: true,
    add: true,
    edit: true,
    delete: true,
  },

  stockTransfer: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    approveReject: true,
  },
  stockTransferW2W: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    approveReject: true,
  },

  stockTransferW2S: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    approveReject: true,
  },
  grnPo: {
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
  grn: {
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
  warehousePo: {
    view: true,
    add: true,
    edit: true,
    delete: true,
  },
};
let permission = {
  B: B_Permission,
  BO: BO_Permission,
  PO: PO_Permission,
  AM: AM_Permission,
  CA: CAM_Permission,
  SM: SM_Permission,
  WM: WM_Permission,
  OM: OM_Permission,
  Nine_Five_Three_Permission,
  R: {},
};

export { permission };
