import Utils from "../../../utility";
import moment from "moment";
const initialState = {
  POList: [],
  order: "DESC",
  sortField: "createdAt",
  offset: 1,
  take: 10,
  search: "",
  count: 0,
  PODetail: [],
  storeId: null,
  storeName: "",
  productName: "",
  endDate: moment().format("YYYY-MM-DD"),
  startDate: moment(new Date()).subtract(30, "d").format("YYYY-MM-DD"),
  productId: null,
};
export const POReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${Utils.ActionName.PO}`:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};
