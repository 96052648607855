import * as React from "react";
import { useState, useRef } from "react";
import Utils from "../../../utility";
// import { CSVLink } from "react-csv";
import { useReactToPrint } from "react-to-print";
import Logo from "../../../assets/images/New Logo .jpg";

// import {
//   deleteStore,
//   getBiller,
//   getBrandList,
//   getOprationManagerList,
//   getPropertyOwner,
//   // getStoreList,
// } from "./action";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// import Paper from "@mui/material/Paper";
// import Checkbox from "@mui/material/Checkbox";
// import IconButton from "@mui/material/IconButton";
// import Tooltip from "@mui/material/Tooltip";
// import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
// import DeleteIcon from '@mui/icons-material/Delete';
// import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
// import Stack from "@mui/material/Stack";
// import Modal from "../../../component/modal";
// import InputAdornment from "@mui/material/InputAdornment";
// import SearchIcon from "@mui/icons-material/Search";
// import { borderBottom } from "@mui/system";
import moment from "moment";
import { Divider } from "@mui/material";
// import Modal from "../../component/modal"
// import AddUser from "./addstore";
// const headers = [
//   { label: "Product ID", key: "ProductID" },
//   { label: "Product Name", key: "ProductName" },
//   { label: "Batch", key: "Batch" },
//   { label: "Invoice Quantity", key: "InvoiceQuantity" },
//   { label: "Recd. Quantity", key: "RecdQuantity" },
//   { label: "MRP", key: "MRP" },

//   { label: "Total Amount", key: "TotalAmount" },
//   { label: "Vendor Name", key: "venderName" },
//   { label: "Po", key: "po" },
//   { label: "Invoice", key: "Invoice" },
//   { label: "GRN by", key: "GRNBy" },
//   { label: "Vehicle", key: "vehicle" },
// ];

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "Product ID",
    sorting: false,
  },
  {
    id: "storename",
    numeric: false,
    disablePadding: true,
    label: "Product Name",
    sorting: false,
  },
  {
    id: "oprator",
    numeric: false,
    disablePadding: true,
    label: "Description",
    sorting: false,
  },
  {
    id: "address",
    numeric: false,
    disablePadding: true,
    label: "barcode ",
    sorting: false,
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: true,
    label: "Quantity",
  },

  {
    id: "weight",
    numeric: false,
    disablePadding: true,
    label: "weight",
  },
];

function EnhancedTableHead(props) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ background: "black" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              orderBy === headCell?.id ? order.toLowerCase() : false
            }
          >
            {headCell?.sorting ? (
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={
                  orderBy === headCell?.id ? order.toLowerCase() : "asc"
                }
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell.label}
                {orderBy === headCell?.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  //onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  const { PODetail } = useSelector((state) => state.POReducer);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        // variant="h6"
        id="tableTitle"
        component="div"
      >
        <b>PO#:</b> {PODetail?.poNumber ? PODetail?.poNumber : "-"}
      </Typography>
      <Typography
        sx={{ flex: "1 1 100%" }}
        // variant="h6"
        id="tableTitle"
        component="div"
      >
        <b>createdBy:</b> {PODetail?.createdBy?.name}
      </Typography>
      <Typography
        sx={{ flex: "1 1 100%" }}
        // variant="h6"
        id="tableTitle"
        component="div"
      >
        <b>type:</b> {PODetail?.type}
      </Typography>

      <Typography
        sx={{ flex: "1 1 100%" }}
        // variant="h6"
        id="tableTitle"
        component="div"
      >
        <b>Store Name:</b> {PODetail?.store?.storename}
      </Typography>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const dispatch = useDispatch();
  const [cvcData, setCvcData] = useState([]);
  // const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  // const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  // const [originalRow, setOriginalRow] = useState([]);
  // const [isEdit, setIsEdit] = useState(null);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  // const handleRequestSort = (event, property) => {
  //   const isAsc = sortField === property && order === "ASC";
  //   dispatch({
  //     type: Utils.ActionName.STORE,
  //     payload: { sortField: property, order: isAsc ? "DESC" : "ASC" },
  //   });
  //   // dispatch(getStoreList());
  // };

  // const handleChangePage = (event, newPage) => {
  //   dispatch({
  //     type: Utils.ActionName.STORE,
  //     payload: { offset: newPage + 1 },
  //   });
  //   // dispatch(getStoreList());
  // };
  const { PODetail } = useSelector((state) => state.POReducer);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    dispatch({
      type: Utils.ActionName.STORE,
      payload: { take: event.target.value, offset: 1 },
    });
    // dispatch(getStoreList());
  };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };
  // const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0
  //     ? Math.max(0, (1 + page) * rowsPerPage - propertyOwnerList.length)
  //     : 0;
  const { propertyOwnerList } = useSelector(
    (state) => state.propertyOwnerReducer
  );
  // const handleSearch = (e) => {
  //   const searchedVal = e.target.value.trim();
  //   dispatch({
  //     type: Utils.ActionName.STORE,
  //     payload: { search: searchedVal },
  //   });
  //   // dispatch(getStoreList());
  // };
  React.useEffect(() => {
    setRows(propertyOwnerList);
  }, [propertyOwnerList]);

  const { GRNDetail } = useSelector((state) => state.GRNReducer);
  React.useEffect(() => {
    if (GRNDetail?.grnProducts?.length) {
      const GRNCVC = GRNDetail?.grnProducts.map((item) => {
        return {
          ProductID: item?.product?.id,
          ProductName: item?.product?.name,
          Batch: item.batch,
          InvoiceQuantity: item.invoiceQuantity,
          RecdQuantity: item.recievedQuantity,
          MRP: item.printedMrp,

          TotalAmount: item.recievedQuantity * item.printedMrp,
          Vendor: GRNDetail?.vender?.alias ? GRNDetail?.vender?.alias : "",
          Po: GRNDetail?.PO ? GRNDetail?.PO : "",
          Invoice: GRNDetail?.invoiceNumber ? GRNDetail?.invoiceNumber : "",
          GRN: "",
          Vehicle: "",
        };
      });
      setCvcData(GRNCVC);
    }
  }, [GRNDetail?.grnProducts]);
  const PoPdf = () => {
    return (
      // <div ref={componentRef} style={{ margin: 10 }}>
      //   <div>
      //     <p style={{ fontSize: 14, fontWeight: 500, marginBottom: 5 }}>
      //       NEW SHOP CSTORE NETWORK PRIVATE LIMITED
      //     </p>
      //     <p style={{ fontSize: 14, marginTop: 0 }}>
      //       C 39, East Of Kailash New Delhi, India, 110065
      //       <br />
      //       GSTIN: 07AAHCN8911F1ZE
      //     </p>
      //   </div>
      //   <p style={{ fontSize: 18, fontWeight: 700, textAlign: "center" }}>
      //     Purchase Order
      //   </p>
      //   <div style={{ display: "flex", justifyContent: "space-between" }}>
      //     <p>
      //       PO Expiry Date
      //       <br />
      //       {moment(PODetail?.expiryDate).format("DD-MM-YYYY")}
      //     </p>
      //     <p>
      //       PO ID
      //       <br />
      //       {PODetail?.poNumber}
      //     </p>
      //   </div>
      //   <div
      //     style={{
      //       display: "flex",
      //       justifyContent: "space-between",
      //       borderBottom: "2px dotted gray",
      //     }}
      //   >
      //     <p>
      //       Delivery Date
      //       <br />
      //       {moment(PODetail?.deliveryDate).format("DD-MM-YYYY")}
      //     </p>
      //     <p>
      //       Terms
      //       <br />
      //       {PODetail?.terms || "N/A"}
      //     </p>
      //   </div>
      //   <div style={{ display: "flex", justifyContent: "space-between" }}>
      //     <p>
      //       Vendor
      //       <br />
      //       {PODetail?.vendor?.alias}
      //       <br />
      //       {PODetail?.vendor?.user?.address}
      //       <br />
      //       {PODetail?.vendor?.user?.contactEmail}
      //     </p>
      //     <p>
      //       Ship To
      //       <br />
      //       THE NEW SHOP
      //       <br />
      //       {PODetail?.store?.storename}
      //       <br />
      //       {PODetail?.store?.GSTIN}
      //     </p>
      //   </div>
      //   <div>
      //     <table
      //       id="tab_customers"
      //       style={{
      //         border: "1px solid gray",
      //         borderCollapse: "collapse",
      //         width: "100%",
      //       }}
      //     >
      //       <thead>
      //         <tr style={{ borderBottom: "1px solid gray" }}>
      //           <th style={{ borderRight: "1px solid gray" }}>S.N</th>
      //           <th style={{ borderRight: "1px solid gray" }}>Product Name</th>
      //           <th style={{ borderRight: "1px solid gray" }}>Barcode</th>
      //           <th style={{ borderRight: "1px solid gray" }}>MRP</th>
      //           <th style={{ borderRight: "1px solid gray" }}>Quantity</th>
      //           <th style={{ borderRight: "1px solid gray" }}>
      //             Unit Price (incl. GST)
      //           </th>
      //           <th style={{ borderRight: "1px solid gray" }}>Total Value</th>
      //         </tr>
      //       </thead>
      //       <tbody>
      //         {PODetail?.poProducts?.map((row, index) => (
      //           <tr key={index} style={{ borderBottom: "1px solid gray" }}>
      //             <td style={{ borderRight: "1px solid gray" }}>{++index}</td>
      //             <td style={{ borderRight: "1px solid gray" }}>
      //               {row?.product?.name}
      //             </td>
      //             <td style={{ borderRight: "1px solid gray" }}>
      //               {row?.product?.barcode ? row?.product?.barcode : "-"}
      //             </td>
      //             <td style={{ borderRight: "1px solid gray" }}>
      //               {row?.productSellingPrice || "-"}
      //             </td>
      //             <td style={{ borderRight: "1px solid gray" }}>
      //               {row?.requiredQuantity || "-"}
      //             </td>
      //             <td style={{ borderRight: "1px solid gray" }}>
      //               {row?.productCostPrice}{" "}
      //             </td>
      //             <td style={{ borderRight: "1px solid gray" }}>
      //               {(row?.productCostPrice * row?.requiredQuantity).toFixed(
      //                 2
      //               ) || 0}
      //             </td>
      //           </tr>
      //         ))}
      //         <tr style={{ borderBottom: "1px solid gray" }}>
      //           <td
      //             colSpan="6"
      //             style={{
      //               textAlign: "center",
      //               borderLeft: "1px solid gray",
      //             }}
      //           >
      //             total value
      //           </td>
      //           <td style={{ borderLeft: "1px solid gray" }}>
      //             {PODetail?.totalValue || 0}
      //           </td>
      //         </tr>
      //       </tbody>
      //     </table>
      //   </div>
      //   <div>
      //     <p>Terms and Conditions:</p>
      //     <p>1. Please acknowledge the receipt of the purchase order.</p>
      //     <p>
      //       2. State product code and purchase order number on all invoices and
      //       delivery notes to facilitate timely payments.
      //     </p>
      //     <p>
      //       3. Stock will be accepted only if the validity of the stock is more
      //       then 80%.
      //     </p>
      //   </div>
      //   <di>
      //     <p>For NEW SHOP CSTORE NETWORK PRIVATE LIMTED</p>
      //     <div></div>
      //   </di>
      // </div>
      <div className="preview-wrap" ref={componentRef}>
        <div className="border_btm">
          <div
            className="Dflex al-cnt js-cnt m-t-20"
            // style={{ width: "100%", margin: "0 auto" }}
          >
            <img
              width={300}
              src={Logo}
              alt="Logo"
              // className={`company_logo ${
              //   !organizationDeatil?.companyLogoUrl
              //     ? "placeholder"
              //     : ""
              // }`}
            />
          </div>
          <div
            style={{
              textAlign: "center",
              fontSize: "24px",
              fontWeight: 500,
              marginTop: "10px",
            }}
          >
            <p>Purchase Order</p>
          </div>
        </div>
        <table className="preview-table">
          <tbody>
            <tr>
              <td className="main_td">
                <table>
                  {/* <thead>
                    <tr>
                      <th colSpan={1}></th>
                      <th colSpan={1}></th>
                    </tr>
                  </thead> */}
                  {/* <Divider width={"200%"} /> */}
                  <tbody>
                    <tr className="border_btm">
                      <td colSpan={1}>
                        <div className="company_name">
                          {"NEW SHOP CSTORE NETWORK PRIVATE LIMITED" || "N/A"}
                        </div>
                        <div className="Dflex al-cnt details">
                          C 39, East Of Kailash New Delhi, India, 110065
                        </div>
                        <div className="Dflex al-cnt details">
                          GSTIN: 07AAHCN8911F1ZE
                        </div>
                      </td>
                      <td
                        className="pb-10 text_right v-top Dflex fl-dir-c al-end m-t-50"
                        colSpan={1}
                      >
                        <p className="info_box">
                          PO Id:
                          <span className="txt">{PODetail?.poNumber}</span>
                        </p>
                        <p className="info_box">
                          PO Expiry Date:
                          <span className="txt">
                            {moment(PODetail?.expiryDate).format("DD-MM-YYYY")}
                          </span>
                        </p>
                        <p className="info_box">
                          {/* Ref ID:{" "}
                          <span className="txt">{"data?.referenceNumber"}</span> */}
                          Delivery Date
                          <span className="txt">
                            {moment(PODetail?.deliveryDate).format(
                              "DD-MM-YYYY"
                            )}
                          </span>
                        </p>
                        {/* <p className="info_box"> */}
                        {/* GST Number:{" "}
                          <span className="txt">
                            {"organizationDeatil?.documentInfo?.gst" || "N/A"}
                          </span> */}
                        {/* </p> */}
                      </td>
                      {/* <Divider /> */}
                    </tr>
                    {/* <tr colSpan={2}> */}
                    {/* <Divider width="200%" /> */}
                    {/* </tr> */}
                    {/* <tr className="border_btm">
                      <td className="pb-10 v-top">
                        <div className="Dflex al-cnt details">
                          C 39, East Of Kailash New Delhi, India, 110065
                        </div>
                        <div className="Dflex al-cnt details">
                          GSTIN: 07AAHCN8911F1ZE
                        </div>
                       
                      </td>
                    </tr> */}
                    <tr>
                      <td colSpan={2}>
                        <div className="flex_row">
                          <div className="col-4">
                            <div className="heading">Vendor Name</div>
                            <p className="company">{PODetail?.vendor?.alias}</p>
                            <address>{PODetail?.vendor?.user?.address}</address>
                            <div>{PODetail?.vendor?.user?.contactEmail}</div>
                          </div>
                          <div className="col-4">
                            <div className="heading">Ship To</div>
                            <p className="company"> THE NEW SHOP</p>
                            <p className="info_box m-b-0">
                              Store Name:
                              <span className="txt">
                                {PODetail?.store?.storename}
                              </span>
                            </p>
                            {/* <address></address> */}
                            <p> </p>
                            <p className="info_box m-b-0">
                              GSTIN:
                              <span className="txt">
                                {PODetail?.store?.GSTIN}
                              </span>
                            </p>
                          </div>
                          {/* <div className="col-4 text_right">
                            <div className="heading">Place of Supply</div>
                            <p className="place">
                              {data?.placeOfSupply || "N/A"}
                            </p>
                          </div> */}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <table className="item_table">
                          <tr>
                            {/* <th>TYPE</th> */}
                            <th>S.N.</th>
                            <th>Product Name</th>
                            <th>Barcode</th>
                            <th>MRP</th>
                            <th>Quantity</th>
                            <th>Unit Price (incl. GST)</th>
                            <th style={{ borderRight: "1px solid gray" }}>
                              Total Value
                            </th>
                          </tr>
                          {PODetail?.poProducts &&
                            PODetail?.poProducts?.length > 0 &&
                            PODetail?.poProducts?.map((item, index) => (
                              <tr className="border_btm" key={index}>
                                <td className="">{++index}</td>
                                <td className="text_name">
                                  {item?.product?.name}
                                </td>

                                <td>
                                  {item?.product?.barcode
                                    ? item?.product?.barcode
                                    : "N/A"}
                                </td>
                                <td>{`${item?.productSellingPrice || "0"}`}</td>
                                <td>{item?.requiredQuantity || "0"} </td>
                                <td>{item?.productCostPrice || "0"} </td>
                                <td>
                                  {(
                                    item?.productCostPrice *
                                    item?.requiredQuantity
                                  ).toFixed(2) || 0}
                                </td>
                              </tr>
                            ))}
                          <tr className="subtotal">
                            <td
                              // colSpan={isGSTEnable ? 6 : 5}
                              // className="text_right"

                              style={{ textAlign: "right" }}
                              colSpan={6}
                            >
                              Total Value
                            </td>
                            <td colSpan={2} className="text_right">
                              ₹ {PODetail?.totalValue || 0}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      {/* <p>1. Please acknowledge the receipt of the purchase order.</p>
      //     <p>
      //       2. State product code and purchase order number on all invoices and
      //       delivery notes to facilitate timely payments.
      //     </p>
      //     <p>
      //       3. Stock will be accepted only if the validity of the stock is more
      //       then 80%.
      //     </p>
      //   </div>
      //   <di>
      //     <p>For NEW SHOP CSTORE NETWORK PRIVATE LIMTED</p> */}
                      <td className="v-top terms" colSpan={7}>
                        <p className="sm">Terms and conditions:</p>
                        <ol>
                          <li>
                            Please acknowledge the receipt of the purchase order
                          </li>
                          <li>
                            State product code and purchase order number on all
                            invoices and delivery notes to facilitate timely
                            payments.
                          </li>
                          <li>
                            Stock will be accepted only if the validity of the
                            stock is more then 80%. For NEW SHOP CSTORE NETWORK
                            PRIVATE LIMTED
                          </li>
                        </ol>
                        {/* {data?.termsAndCondition ? (
                          <ol>
                            <li>{data?.termsAndCondition || ""}</li>
                          </ol>
                        ) : (
                          <></>
                        )} */}
                      </td>
                      {/* <td className="v-top terms">
                        <div className="CusaccountDetails">
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <div className="Hlb">Bank Details:</div>
                              <div className="lb">Account Holder Name:</div>
                              <div className="vl">
                                {bankDetails?.accountName ||
                                  userProfileData?.bankDetails?.accountName ||
                                  "N/A"}
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <div className="lb">Account Number:</div>
                              <div className="vl">
                                {bankDetails?.accountNumber ||
                                  userProfileData?.bankDetails?.accountNumber ||
                                  "N/A"}
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <div className="lb">IFSC Code:</div>
                              <div className="vl">
                                {bankDetails?.ifscCode ||
                                  userProfileData?.bankDetails?.ifscCode ||
                                  "N/A"}
                              </div>
                            </Grid>
                            <Grid item xs={8}>
                              <div className="lb">Branch Name:</div>
                              <div className="vl">
                                {bankDetails?.branchName ||
                                  userProfileData?.bankDetails?.branchName ||
                                  "N/A"}
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <p style={{ marginTop: "20px", fontWeight: "600" }}>
                          For: {organizationDeatil?.companyName || "N/A"}
                        </p>
                        <div className="flex_col">
                          <div className="sign">
                            <img
                              src={
                                data?.signature ? data?.signature : BLUEMAINLOGO
                              }
                              alt=""
                            />
                          </div>

                          <div className="rt">
                            <p className="authorized">Authorized Signatory</p>
                          </div>
                        </div>
                      </td> */}
                    </tr>
                  </tbody>
                  {/* <tfoot>
                    <tr>
                      <td
                        colSpan={2}
                        style={{ borderTop: "1px solid #DCE0FF" }}
                      >
                        <p className="poweredBy">
                          Powered By:{" "}
                          <img
                            style={{ marginLeft: "10px" }}
                            src={BLUEMAINLOGO}
                            alt=""
                          />
                        </p>
                      </td>
                    </tr>
                  </tfoot> */}
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  return (
    <>
      {PoPdf()}
      <Button variant="outlined" onClick={handlePrint}>
        Print this out{" "}
      </Button>
      {/* <button >!</button> */}
    </>
  );
}
