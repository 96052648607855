import Utils from "../../../utility";

const initialState = {
  products: [],
  images: [],
  count: 0,
  order: "DESC",
  sortField: "",
  offset: 1,
  take: 10,
  search: "",
  count: 0,
  brandId: "",
  categoryId: "",
};
export const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${Utils.ActionName.PRODUCTS}`:
      return {
        ...state,
        ...action.payload,
      };
    case `${Utils.ActionName.RESET_PRODUCTS}`:
      return initialState;
    default:
      return { ...state };
  }
};
