import * as React from "react";
import { useState } from "react";
import Modal from "../../../component/modal";
import AddButton from "../../../component/button/buttonWithIcon";
import Breadcrumbs from "../../../component/breadcrumb";
import SearchInput from "../../../component/searchInput";

import Utils from "../../../utility";
import { CreditByStore, CreditByStoreId, creditEmailAndSms } from "./action";
import PropTypes from "prop-types";
import useDebounce from "../../../hooks/debounce";
import AutoComplete from "../../../component/input/autocomplete";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CreditOrders from "./CreditOrders";

const headCells = [
  {
    id: "S.No",
    numeric: true,
    disablePadding: true,
    label: "S.No",
    sorting: false,
  },
  {
    id: "customerName",
    numeric: false,
    disablePadding: true,
    label: "Customer Name",
    sorting: true,
  },
  {
    id: "Phone/Email",
    numeric: true,
    disablePadding: false,
    label: "Phone/Email",
    sorting: false,
  },
  {
    id: "totalCredit",
    numeric: true,
    disablePadding: false,
    label: "Total Credit",
    sorting: true,
  },
  {
    id: "UnpaidCredit",
    numeric: true,
    disablePadding: false,
    label: "Unpaid Credit",
    sorting: false,
  },
  {
    id: "UnpaidCredit",
    numeric: true,
    disablePadding: false,
    label: "",
    sorting: false,
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "",
    sorting: false,
  },
];

function EnhancedTableHead(props) {
  const { onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { order, sortField } = useSelector((state) => state.customerReducer);
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              sortField === headCell.id ? order.toLowerCase() : false
            }
          >
            {headCell?.sorting ? (
              <TableSortLabel
                active={sortField === headCell.id}
                direction={
                  sortField === headCell.id ? order.toLowerCase() : "asc"
                }
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {sortField === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "DESC"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  //onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Customer
      </Typography>
    </Toolbar>
  );
};

export default function EnhancedTable() {
  const [open, setOpen] = useState(false);
  const getStoreId = localStorage.getItem("storeId");
  const dispatch = useDispatch();
  const [customerId, setCustomerId] = useState(0);

  const { offset, count, take, sortField, order, creditData, storeId, search } =
    useSelector((state) => state.creditsReducer);
  const handleSearch = (e) => {
    dispatch({
      type: Utils.ActionName.CREDIT,
      payload: { search: e.target.value, take: take, offset: 1 },
    });
  };
  const handleSearchClear = (e) => {
    dispatch({
      type: Utils.ActionName.CREDIT,
      payload: { search: "", take: take, offset: 1 },
    });
  };

  const handleChangePage = (event, newPage) => {
    dispatch({
      type: Utils.ActionName.CREDIT,
      payload: { offset: newPage + 1 },
    });
    dispatch(CreditByStore(storeId, ""));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: Utils.ActionName.CREDIT,
      payload: { take: event.target.value, offset: offset },
    });
    dispatch(CreditByStore(storeId, ""));
  };

  const openView = (id, amount) => {
    setOpen(true);
    dispatch(CreditByStoreId(id, storeId));
    // dispatch(creditEmailAndSms(getStoreId,id))
    setCustomerId(id);
  };

  const sendEmail = (id) => {
    dispatch(creditEmailAndSms(storeId, id));
  };

  //   React.useEffect(() => {
  //     dispatch(CreditByStore(getStoreId, ""));
  //   }, [dispatch]);
  const { storeListForDropdown } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Breadcrumbs />
      <Box className="tableContainer">
        <div className="Dflex table-filter-head ">
          <AutoComplete
            freeSolo={false}
            className="w-300"
            // onBlur={handleBlur}
            values={storeId}
            onChange={(event, value) => {
              if (value !== null) {
                dispatch({
                  type: Utils.ActionName.CREDIT,
                  payload: { storeId: value?.id },
                });
                dispatch(CreditByStore(value?.id));
              }
            }}
            name={"storeId"}
            label={"Store Name"}
            options={storeListForDropdown}
            getOptionLabel={(option) => option?.storename}
            // isRequired={true}
            getOptionSelected={(option, value) => {
              return `${option.id}` === `${value.id}`;
            }}
            // defaultValue={() => {
            //   const index = storeListForDropdown?.findIndex(
            //     (x) => x?.id == storeId
            //   );
            //   return storeListForDropdown[index];
            // }}
          />
          <Box className="search_bar ">
            <SearchInput
              value={search}
              placeholder="Search here..."
              handleSearch={handleSearch}
              clearSearch={handleSearchClear}
            />
          </Box>
        </div>

        <TableContainer>
          <EnhancedTableToolbar />
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={sortField}
              // onRequestSort={handleRequestSort}
            />
            <TableBody>
              {creditData.map((data, i) => (
                <TableRow key={data?.customer_id}>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell>{data?.customer_name}</TableCell>
                  <TableCell>
                    {data?.customer_phoneNumber && data?.customer_email
                      ? `${data?.customer_phoneNumber} / ${data?.customer_email}`
                      : data?.customer_phoneNumber
                      ? data?.customer_phoneNumber
                      : data?.customer_email
                      ? data?.customer_email
                      : null}
                  </TableCell>
                  <TableCell>{data?.totalcredit}</TableCell>
                  <TableCell>{data?.unpaidcredit}</TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      openView(data?.customer_id, data?.totalOrderAmount)
                    }
                  >
                    View
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    onClick={() => sendEmail(data?.customer_id)}
                  >
                    {" "}
                    {data?.customer_email
                      ? "Send Email"
                      : data?.customer_phoneNumber
                      ? "Send SMS"
                      : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={count}
          rowsPerPage={take}
          page={offset - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Modal
        header={`Add product Invoice`}
        children={<CreditOrders customerId={customerId} setOpen={setOpen} />}
        handleClose={() => {
          setOpen(false);
          // setIsEdit(null);
        }}
        open={open}
        minWidth={"1200px"}
      />
    </>
  );
}
