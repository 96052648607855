import React from "react";
import { createStyles, TextField, Theme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { getProductsForDropdown } from "../../pages/productRelated/products/action";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";

// const styles = makeStyles((theme) =>
//   createStyles({
//     inputField: {

//       "& .MuiInputBase-input": {
//         font: "normal normal normal 14px/14px Roboto",
//         letterSpacing: "0.07px",
//         // color: '#0000008A',
//         opacity: 1,
//       },
//       "& .MuiFormLabel-root": {
//         font: "normal normal normal 12px/21px Roboto",
//         letterSpacing: "0.07px",
//         // color: '#0000008A',
//         opacity: 1,
//       },
//       "& .MuiFormControl-marginNormal": {
//         marginTop: "unset",
//       },
//       "& svg": {},
//     },
//     errorMessage: {},
//     required: {
//       color: "#D32F2F",
//     },
//     underline: {
//       width: 100,
//       "& .MuiInput-underline::before": {
//         borderBottom: "none",
//       },
//       "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
//         borderBottom: "none",
//       },
//       "& .MuiInput-underline::after": {
//         borderBottom: "none",
//       },
//       "& .MuiAutocomplete-fullWidth": {
//         // width: 100,
//       },
//     },
//   })
// );

export default function AutoComplete(
  {
    label,
    placeholder,

    id,
    name,
    type,
    onBlur,
    values,
    onChange,
    style,
    helperText,
    isRequired,
    error,
    options,
    getOptionLabel,
    onInputChange,
    getOptionSelected,
    inputValue,
    defaultValue,
    key,
    onClose,
    isDisabled,
    select = false,
    freeSolo = false,
    className = "",
  } // defaultProps,
) {
  const dispatch = useDispatch();
  const { productsDropDownList } = useSelector((state) => state.commonReducer);
  // const classes = styles();
  // createFilterOptions() => filterOptions
  const filterOptions = createFilterOptions({ limit: 500 });
  // debugger;
  return (
    // <Box key={key}>
    <Autocomplete
      // key={Date.now()}
      name={name}
      // {...defaultProps}
      freeSolo={freeSolo}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      id={id}
      autoSelect={select}
      options={options}
      // defaultValue={''}
      disabled={isDisabled}
      disableClearable
      onClose={onClose}
      // inputValue={inputValue ? inputValue : ''}
      onInputChange={(e) => {
        if (!productsDropDownList?.length && name.includes("product")) {
          dispatch(getProductsForDropdown());
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          // label={label}
          disabled={isDisabled}
          label={
            <span>
              {label}
              {isRequired ? <span> *</span> : <span></span>}
            </span>
          }
          // margin="normal"
          helperText={helperText}
          error={error}
          name={name}
          type={type}
          value={values ? values : ""}
          defaultValue={values}
          // defaultValue={''}
          // required={isRequired}
          // inputProps={{ autocomplete: 'off' }}
          // inputProps={{
          //     underline: {
          //         '&::before': {
          //             borderBottom: 'none',
          //         },
          //         '&::after': {
          //             borderBottom: 'none',
          //         },
          //     },
          // }}
        />
      )}
      onBlur={onBlur}
      // value={values}
      onChange={onChange}
      placeholder={placeholder}
      fullWidth
      size={"small"}
      className={className}
      // className={
      //   select
      //     ? `${classes.inputField} ${classes.underline}`
      //     : classes.inputField
      // }
      // getOptionSelected={getOptionSelected}
      // defaultValue={'-'}
      defaultValue={defaultValue}
    />
    // </Box>
  );
}
